export const isBlank = val => {
  const trimmedVal = val.trim()
  if (!trimmedVal && trimmedVal.length === 0) {
    return true
  }
  return false
}

export const registerValidations = values => {
  const errors = {}
  const { username, email, password, terms } = values

  if (isBlank(username)) errors.username = 'Please enter your name'
  else if (username.length < 3)
    errors.username = 'Name should be atleast 3 characters'

  if (isBlank(email)) errors.email = 'Email address is required'
  else if (!/\S+@\S+\.\S+/.test(email))
    errors.email = 'Email address is invalid'

  if (isBlank(password)) errors.password = 'Password is required'
  else if (password.length < 8)
    errors.password = 'Password must be 8 or more characters'

  if (!terms) errors.terms = 'Please agree our terms of service.'

  return errors
}

export const loginValidations = values => {
  const errors = {}
  const { email, password } = values
  if (isBlank(email)) errors.email = 'Email address is required'
  else if (!/\S+@\S+\.\S+/.test(email))
    errors.email = 'Email address is invalid'

  if (isBlank(password)) errors.password = 'Password is required'
  return errors
}

export const premiumKeyValidations = values => {
  const errors = {}
  const { premiumKey } = values
  if (isBlank(premiumKey)) errors.premiumKey = 'Please enter Premium key'
  else if (!/^cbx\d{2}/.test(premiumKey) || premiumKey.length !== 15)
    errors.premiumKey = 'Invalid Premium key'
  return errors
}

export const forgotPasswordValidations = values => {
  const errors = {}
  const { email } = values
  if (isBlank(email)) errors.email = 'Email address is required'
  else if (!/\S+@\S+\.\S+/.test(email))
    errors.email = 'Email address is invalid'
  return errors
}

export const resetPasswordValidation = values => {
  const errors = {}
  const { password, confirmPassword } = values

  if (isBlank(password)) errors.password = 'New password is required'
  else if (password.length < 8)
    errors.password = 'New Password must be 8 or more characters'

  if (isBlank(confirmPassword))
    errors.confirmPassword = 'Confirm password is required'
  else if (confirmPassword !== password)
    errors.confirmPassword = "Confirm Password doesn't match"

  return errors
}

export const changePasswordValidations = values => {
  const errors = {}
  const { oldPassword, newPassword, confirmPassword } = values

  if (isBlank(oldPassword)) errors.password = "Old password can't be blank"

  if (isBlank(newPassword)) errors.newPassword = 'New password is required'
  else if (newPassword.length < 8)
    errors.newPassword = 'New Password must be 8 or more characters'

  if (isBlank(confirmPassword))
    errors.confirmPassword = 'Confirm password is required'
  else if (confirmPassword !== newPassword)
    errors.confirmPassword = "Confirm Password doesn't match"

  return errors
}

export const contactFormValidations = values => {
  const errors = {}
  const { username, email, issueType, message } = values

  if (isBlank(username)) errors.username = 'Please enter your name'
  else if (username.length < 3)
    errors.username = 'Name should be atleast 3 characters'

  if (isBlank(email)) errors.email = 'Email address is required'
  else if (!/\S+@\S+\.\S+/.test(email))
    errors.email = 'Email address is invalid'

  if (isBlank(issueType)) errors.issueType = 'Please select the issue.'

  if (isBlank(message)) errors.message = 'Please enter your message.'
  else if (message && message.length < 10)
    errors.message = 'Message should be atleast 10 characters'

  return errors
}

export const deleteAccountValidations = values => {
  const errors = {}
  const { feedback } = values

  if (isBlank(feedback)) errors.feedback = 'Please enter your feedback.'
  else if (feedback && feedback.length < 30)
    errors.feedback = 'Your feedback should be atleast 35 characters.'

  return errors
}
