import React from 'react'
import PropTypes from 'prop-types'
import { Alert } from 'reactstrap'

const CustomAlert = ({ type, children }) => (
  <Alert color={type} className="mt-3 mb-0">
    {children}
  </Alert>
)

CustomAlert.propTypes = {
  type: PropTypes.string.isRequired,
  children: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default CustomAlert
