import React from 'react'
import PropTypes from 'prop-types'
import { FormGroup, Label, Input, FormFeedback } from 'reactstrap'

const CheckBox = ({ inlineText, isChecked, onChangeInput, error, name }) => (
  <FormGroup check inline>
    <div className="row">
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        <Label check>
          <Input
            name={name}
            type="checkbox"
            checked={isChecked}
            onChange={onChangeInput}
          />{' '}
          {inlineText}
        </Label>
      </div>
      <div className="col-lg-12 col-md-12 col-sm-12 col-12">
        {error && <FormFeedback className="d-block">{error}</FormFeedback>}
      </div>
    </div>
  </FormGroup>
)

CheckBox.defaultProps = {
  isChecked: false,
  error: '',
}

CheckBox.propTypes = {
  name: PropTypes.string.isRequired,
  onChangeInput: PropTypes.func.isRequired,
  isChecked: PropTypes.bool,
  inlineText: PropTypes.string.isRequired,
  error: PropTypes.string,
}

export default CheckBox
