import { useState, useEffect } from 'react'

const useForm = (init, validations, callback) => {
  const [values, setValues] = useState(init)
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)

  useEffect(() => {
    if (Object.keys(errors).length === 0 && isSubmitting) {
      if (init.captcha) {
        const { grecaptcha } = window
        grecaptcha.execute()
      }
      callback(values)
      setIsSubmitting(false)
    }
  }, [errors])

  const handleOnChange = e => {
    const {
      target: { type, name, value },
    } = e
    setValues({
      ...values,
      [name]: type === 'checkbox' ? e.target.checked : value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    setErrors(validations(values))
    setIsSubmitting(true)
  }

  const resetForm = () => {
    setValues(init)
    if (init.captcha) {
      const { grecaptcha } = window
      if (grecaptcha) grecaptcha.reset()
    }
  }
  return {
    values,
    errors,
    setValues,
    isSubmitting,
    resetForm,
    handleSubmit,
    handleOnChange,
  }
}

export default useForm
