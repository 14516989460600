import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import uuid from 'uuid/v4'

const renderers = []

const injectScript = locale => {
  window.GoogleRecaptchaLoaded = () => {
    while (renderers.length) {
      const renderer = renderers.shift()
      renderer()
    }
  }

  const script = document.createElement('script')
  script.id = 'recaptcha'
  script.src = `https://www.google.com/recaptcha/api.js?hl=${locale}&onload=GoogleRecaptchaLoaded&render=explicit`
  script.type = 'text/javascript'
  script.async = true
  script.defer = true
  document.body.appendChild(script)
}

const InvisibleRepatcha = props => {
  let container = useRef(null)
  useEffect(() => {
    const { locale, badge, onResolved, onLoaded } = props
    const callbackName = `GoogleRecaptchaResolved-${uuid()}`
    window[callbackName] = onResolved

    const loaded = () => {
      if (container) {
        const recaptchaId = window.grecaptcha.render(container.current, {
          sitekey: '6Ldq4FwUAAAAAJ81U4lQEvQXps384V7eCWJWxdjf',
          size: 'invisible',
          badge,
          callback: callbackName,
        })
        window.grecaptcha.execute(recaptchaId)
        window.grecaptcha.reset(recaptchaId)
        window.grecaptcha.getResponse(recaptchaId)
        onLoaded()
      }
    }

    if (
      window.grecaptcha &&
      window.grecaptcha.render &&
      window.grecaptcha.execute &&
      window.grecaptcha.reset &&
      window.grecaptcha.getResponse
    ) {
      loaded()
    } else {
      renderers.push(loaded)
      if (!document.querySelector('#recaptcha')) {
        injectScript(locale)
      }
    }
    return () => {
      delete window[callbackName]
      container = null
    }
  }, [])
  return (
    <>
      <div ref={container} />
    </>
  )
}

InvisibleRepatcha.propTypes = {
  locale: PropTypes.string,
  badge: PropTypes.oneOf(['bottomright', 'bottomleft', 'inline']),
  onResolved: PropTypes.func.isRequired,
  onLoaded: PropTypes.func,
}

InvisibleRepatcha.defaultProps = {
  locale: 'en',
  badge: 'bottomright',
  onLoaded: () => {},
}

export default React.memo(InvisibleRepatcha)
