import React from 'react'
import PropTypes from 'prop-types'
import Adsense from '../Adsense'
import styles from './index.module.scss'

const Wrapper = ({ title, children, colSize, showAds = true }) => (
  <section className={styles.wrapper}>
    <div className={`row justify-content-center ${styles.spacing40}`}>
      {showAds && (
        <div className="col-sm-2">
          <Adsense slot="7735580988" format="responsive" />
        </div>
      )}
      <div className={colSize || 'col-sm-8 col-12'}>
        <div className={styles.loginFormPanel}>
          <h3 className={styles.badge}>{title}</h3>
          <div className="row justify-content-center">{children}</div>
        </div>
      </div>
      {showAds && (
        <div className="col-sm-2">
          <Adsense slot="6263357840" format="responsive" />
        </div>
      )}
    </div>
  </section>
)

Wrapper.propTypes = {
  children: PropTypes.any.isRequired,
  colSize: PropTypes.string,
  title: PropTypes.string.isRequired,
  showAds: PropTypes.bool,
}

Wrapper.defaultProps = {
  colSize: 'col-sm-8 col-12',
  showAds: true,
}

export default Wrapper
