import React, { useCallback, useEffect } from 'react'
import { Link, navigate } from 'gatsby'
import { Button } from 'reactstrap'
import { useSelector, useDispatch } from 'react-redux'

import useForm from '../hooks/useForm'
import InputBox from '../components/Form/InputBox'
import CheckBox from '../components/Form/CheckBox'
import Wrapper from '../components/Wrapper'
import CustomAlert from '../components/Alerts'
import InvisibleRepatcha from '../components/InvisibleRepatcha'

import { isUserLoggedIn } from '../selectors/login'

import { DOWNLOADER, LOGIN } from '../data/constants'
import { registerValidations } from '../validations'
import { registerUser } from '../reducers/register'
import SEO from '../components/SEO'

const init = {
  username: '',
  email: '',
  password: '',
  terms: false,
  captcha: true,
}

let registerForm

const Register = () => {
  const isLoggedIn = useSelector(isUserLoggedIn)
  const dispatch = useDispatch()

  const { loading, loaded, isRegistered, error } = useSelector(
    state => state.register
  )

  const {
    values,
    errors,
    handleOnChange,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useForm(init, registerValidations, val => {
    registerForm = val
    return registerForm
  })

  const submitForm = useCallback(
    token => {
      dispatch(
        registerUser({
          ...registerForm,
          token,
        })
      )
      const { grecaptcha } = window
      grecaptcha.reset()
    },
    [isSubmitting]
  )

  useEffect(() => {
    if (isLoggedIn) {
      navigate(DOWNLOADER)
    }
    if (isRegistered) {
      resetForm()
    }
  }, [isLoggedIn, isRegistered])

  return (
    <>
      <SEO page="register" />
      <Wrapper title="Register">
        <div className="col-sm-6">
          <div className="cboxEraForm">
            <form onSubmit={handleSubmit}>
              <InputBox
                name="username"
                label="Username"
                type="text"
                placeholder="Username"
                value={values.username}
                onChangeInput={handleOnChange}
                error={errors.username}
              />
              <InputBox
                name="email"
                label="Email"
                type="email"
                placeholder="Email"
                value={values.email}
                onChangeInput={handleOnChange}
                error={errors.email}
              />
              <InputBox
                name="password"
                label="Password"
                type="password"
                placeholder="Password"
                value={values.password}
                onChangeInput={handleOnChange}
                error={errors.password}
              />
              <CheckBox
                name="terms"
                inlineText="I agree to terms of service."
                isChecked={values.terms}
                onChangeInput={handleOnChange}
                error={errors.terms}
              />
              <div className="clear-fix mb-3" />
              <InvisibleRepatcha onResolved={submitForm} />
              <div className="row">
                <div className="col-lg-6 col-md-6 col-sm-6">
                  <Button type="submit" color="success" disabled={loading}>
                    {loading ? 'Please wait...' : 'Register'}
                  </Button>
                </div>
                <div className="col-lg-6 col-md-6 text-right mt-2">
                  <Link className="interLink" to={LOGIN}>
                    Already Registered ?
                  </Link>
                </div>
              </div>
            </form>
            {loaded && !loading && (
              <CustomAlert type={`${isRegistered ? 'success' : 'danger'}`}>
                <span>
                  {isRegistered ? (
                    <span>
                      Hurray! You've Registered Successfully.
                      <br /> Please check your email for verification.
                    </span>
                  ) : (
                    error.map((err, index) => (
                      <span key={String(index)}>{err}</span>
                    ))
                  )}
                </span>
              </CustomAlert>
            )}
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Register
